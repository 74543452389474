import React, { useState, useRef } from "react"
import { Col, Row } from "react-styled-flexboxgrid"
import SyntaxHighlighter from "react-syntax-highlighter"
import styled, { css } from "styled-components"
import { rem } from "polished"

const El = styled.div`
  padding: ${rem(80)} 0;
  border-top: 1px solid;
  position: relative;

  textarea {
    position: absolute;
    left: -9999px;
    top: 0;
    width: 0;
    height: 0;
  }

  h2 {
    font-size: ${rem(30)};
    font-weight: 700;
    display: inline-block;
  }
`

const Button = styled.button`
  appearance: none;
  opacity: 0.3;
  margin-right: ${rem(10)};
  margin-bottom: ${rem(20)};
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  background: none;
  color: blue;
  font-weight: 700;
  padding: ${rem(10)};

  ${props =>
    props.activeButton &&
    css`
      opacity: 1;
      border: 3px dashed;
    `}
`

const CodeContainer = props => {
  const [activeTab, setActiveTab] = useState(0)

  const refs = [useRef(null), useRef(null), useRef(null)]
  const codes = []

  props.html.length > 0 && codes.push({ type: "html", code: props.html })
  props.javascript.length > 0 &&
    codes.push({ type: "javascript", code: props.javascript })
  props.scss.length > 0 && codes.push({ type: "scss", code: props.scss })

  return (
    <El id={`${props.title.toLowerCase().split(" ").join("-")}`}>
      <Row>
        <Col xs={6} md={3}>
          <h2>{props.title}</h2>
        </Col>

        <Col xs={6} md={9}>
          {codes.map((item, index) => (
            <Button
              key={index}
              activeButton={index === activeTab}
              onClick={() => {
                setActiveTab(index)

                requestAnimationFrame(() => {
                  refs[index].current.select()
                  document.execCommand("copy")
                })
              }}
            >
              {item.type}
            </Button>
          ))}

          {codes.map((item, index) => (
            <React.Fragment key={index}>
              {activeTab == index && (
                <div
                  style={{ maxHeight: "300px", overflow: "scroll" }}
                  onClick={() => {
                    setActiveTab(index)

                    requestAnimationFrame(() => {
                      refs[index].current.select()
                      document.execCommand("copy")
                    })
                  }}
                >
                  <textarea
                    value={item.code}
                    readOnly={true}
                    ref={refs[index]}
                  />
                  <SyntaxHighlighter language={item.type}>
                    {item.code}
                  </SyntaxHighlighter>
                </div>
              )}
            </React.Fragment>
          ))}
        </Col>
      </Row>
    </El>
  )
}

export default CodeContainer
