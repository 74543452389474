const data = [
  {
    title: "Accordion",
    description: "",
    html: `    <div class="js-module-accordion" aria-label="Accordion Control Group Buttons">
    <div class="c-accordion">
      <button class="c-accordion__control" aria-controls="accordion-content-1" aria-expanded="false">Apples</button>
      <div class="c-accordion__content" aria-hidden="true" id="accordion-content-1">
        <div class="c-accordion__content__inner">
          <p>Apples are a fine fruit often associated with good health, and fewer doctor's appointments.</p>
          <p>Example. An apple a day keeps the doctor away.</p>
        </div>
      </div>
    </div>

    <div class="c-accordion">
      <button class="c-accordion__control" aria-controls="accordion-content-2" aria-expanded="false">Content2</button>
      <div class="c-accordion__content" aria-hidden="true" id="accordion-content-2">
        <div class="c-accordion__content__inner">
          <p>Content2 wsup</p>
        </div>
      </div>
    </div>
  </div>`,
    javascript: `import { gsap } from "gsap";

  const toggleAccordion = (accordion, action) => {
    let button = accordion.querySelector(".c-accordion__control");
    let content = accordion.querySelector(".c-accordion__content");
    let setOpen = action === "open";
  
    setOpen ? accordion.classList.add("c-accordion--open") : accordion.classList.remove("c-accordion--open");
  
    button.setAttribute("aria-expanded", setOpen ? true : false);
    content.setAttribute("aria-hidden", setOpen ? false : true);
  
    gsap.to(content, {
      height: setOpen ? "auto" : 0,
      duration: 0.3,
      onStart: () => {},
    });
  };
  
  document.querySelectorAll(".js-module-accordion").forEach((el) => {
    const handleClick = (target) => {
      let accordion = target.closest(".c-accordion");
      let isOpen = target.getAttribute("aria-expanded");
  
      let accordions = target.closest(".accordion-group") || el;
  
      if (isOpen != "false") {
        toggleAccordion(accordion, "close");
      } else {
        accordions.querySelectorAll(".c-accordion--open").forEach((item) => {
          toggleAccordion(item, "close");
        });
  
        toggleAccordion(accordion, "open");
      }
    };
  
    el.querySelectorAll(".c-accordion__control").forEach((button) => {
      button.addEventListener("click", (e) => {
        handleClick(e.target);
      });
    });
  });`,
    scss: `.c-accordion {
    $this: &;
  
    &__content {
      height: 0;
      overflow: hidden;
      visibility: hidden;
      transition: visibility 0s 0.3s;
  
      &__inner {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s 0s, transform 0.3s 0s;
      }
    }
  
    &--open {
      & > #{$this}__content {
        visibility: visible;
        transition: visibility 0s 0s;
  
        & > #{$this}__content__inner {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }`,
  },
  {
    title: "Tabs",
    description: "",
    html: `    <div class="c-tabs js-module-tabs">
    <ul class="c-tabs__tabs-list" role="tablist">
      <li class="c-tabs__tab c-tabs__tab--active">
        <a class="c-tabs__tab--selected" aria-controls="apples" aria-selected="true" href="#apples" id="tab-apples" role="tab"> Apples </a>
      </li>

      <li class="c-tabs__tab">
        <a aria-controls="bananas" aria-selected="false" href="#bananas" id="tab-bananas" role="tab" tabindex="-1">Bananas</a>
      </li>
    </ul>

    <div class="c-tabs__panels-container">
      <div aria-labelledby="tab-apples" class="c-tabs__tab-panel c-tabs__tab-panel--open c-tabs__tab-panel--animate-in" id="apples" aria-hidden="false" role="tabpanel">
        <div class="c-tabs__tab-panel__content">
          tab content for apples
        </div>
      </div>

      <div aria-labelledby="tab-bananas" class="c-tabs__tab-panel" role="tabpanel" aria-hidden="true" id="bananas">
        <div class="c-tabs__tab-panel__content">
          tab content for bananas
        </div>
      </div>
    </div>
  </div>`,
    javascript: `import { gsap } from "gsap";

    document.querySelectorAll(".js-module-tabs").forEach((el) => {
      let currentTab = 0;
      const tabs = el.querySelectorAll(".c-tabs__tab a");
      const tabsContainer = el.querySelector(".c-tabs__panels-container");
      const panels = el.querySelectorAll(".c-tabs__tab-panel");
    
      const tabFocus = () => {
        tabs[currentTab].focus();
      };
    
      const tabSwitch = () => {
        tabsContainer.style.height = tabsContainer.offsetHeight + "px";
    
        panels.forEach((panel) => {
          panel.classList.remove("c-tabs__tab-panel--animate-in");
        });
    
        // make tabs absolute
        tabsContainer.classList.add("c-tabs__panels-container--animating");
    
        // target height
        panels[currentTab].classList.add("c-tabs__tab-panel--open");
    
        window.requestAnimationFrame(() => {
          el.querySelector(".c-tabs__tab-panel--open").classList.remove("c-tabs__tab-panel--animate-in");
    
          gsap.to(tabsContainer, {
            height: panels[currentTab].offsetHeight,
            duration: 0.3,
            onComplete: () => {
              panels.forEach((panel, index) => {
                if (index !== currentTab) {
                  panel.classList.remove("c-tabs__tab-panel--open");
                } else {
                  panel.classList.add("c-tabs__tab-panel--open", "c-tabs__tab-panel--animate-in");
                }
    
                panel.removeAttribute("style");
              });
    
              tabsContainer.classList.remove("c-tabs__panels-container--animating");
              tabsContainer.style.height = "auto";
            },
          });
        });
    
        el.querySelectorAll(".c-tabs__tab").forEach((tab, index) => {
          tab.classList.remove("c-tabs__tab--active");
          tabs[index].setAttribute("aria-selected", false);
          tabs[index].setAttribute("tabindex", -1);
          tabs[index].classList.remove("c-tabs__tab--selected");
        });
    
        tabs[currentTab].setAttribute("aria-selected", true);
        tabs[currentTab].setAttribute("tabindex", 0);
        tabs[currentTab].classList.add("c-tabs__tab--selected");
      };
    
      tabs.forEach((tab, index) => {
        tab.addEventListener("keydown", (e) => {
          // 37 = left
          // 38 = top
          // 39 = right
          // 40 = bottom
    
          if (e.keyCode === 37 || e.keyCode === 38) {
            if (currentTab > 0) {
              currentTab -= 1;
            } else {
              currentTab = tabs.length - 1;
            }
          }
    
          if (e.keyCode === 39 || e.keyCode === 40) {
            if (currentTab != tabs.length - 1) {
              currentTab += 1;
            } else {
              currentTab = 0;
            }
          }
    
          if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
            tabFocus();
            tabSwitch();
          }
        });
    
        tab.addEventListener("click", (e) => {
          e.preventDefault();
          if (currentTab != index) {
            currentTab = index;
    
            tabSwitch();
          }
        });
      });
    });
    `,
    scss: `.c-tabs {
      $this: &;
    
      &__tabs-list {
        display: flex;
      }
    
      &__tab {
        &--selected {
          background: red;
        }
      }
    
      &__tab-panel {
        display: none;
    
        &__content {
          opacity: 0;
          transform: translateY(10px);
          transition: opacity 0.3s, transform 0.3s;
        }
    
        &--open {
          display: block;
        }
    
        &--animate-in {
          .c-tabs__tab-panel__content {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    
      &__panels-container {
        position: relative;
    
        &--animating {
          .c-tabs__tab-panel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }
        }
      }
    }
    `,
  },
  {
    title: "Popup",
    description: "",
    html: `    <button class="popup-button" data-target="popup--some-popup">Open popup</button>

    <div class="popup popup--some-popup" aria-hidden="true" role="dialog" aria-labelledby="dialog1_label" aria-modal="true">
      <div class="popup__overlay"></div>

      <div class="popup__container">
        <div class="popup__content">
          <button class="popup__close-button">close</button>

          <p id="dialog1_label">hello hello popup content here</p>
        </div>
      </div>
    </div>`,
    scss: `.popup {
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      display: flex;
      transition: 0s 0.5s;
      z-index: 9999999;
      $self: &;
      visibility: hidden;
    
      &__overlay {
        background: #fff;
        opacity: 0;
        transition: 0.3s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    
      &--active {
        left: 0;
        transition: 0s 0s;
        visibility: visible;
    
        #{$self}__overlay {
          opacity: 0.5;
        }
    
        #{$self}__content {
          opacity: 1;
          transform: translateY(0);
        }
      }
    
      &__close-button {
        cursor: pointer;
      }
    
      &__container {
        margin: 0 auto;
        display: flex;
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 2rem 0;
      }
    
      &__content {
        max-width: 100%;
        margin: auto auto;
        border-radius: 3rem;
        background: #fff;
        min-width: 100px;
        z-index: 1;
        position: relative;
        opacity: 0;
        transform: translateY(1.5rem);
        transition: opacity 0.3s, transform 0.3s;
      }
    }
    `,
    javascript: `let openPopup = null;

    const closePopup = () => {
      openPopup.classList.remove("popup--active");
      openPopup.setAttribute("aria-hidden", true);
      openPopup = null;
    };
    
    document.querySelectorAll(".popup-button").forEach((el) => {
      el.addEventListener("click", () => {
        let target = el.getAttribute("data-target");
        openPopup = document.querySelector(\`.\${target}\`);
        openPopup.querySelector(".popup__container").scrollTop = 0;
        openPopup.classList.add("popup--active");
        openPopup.setAttribute("aria-hidden", false);
      });
    });
    
    document.querySelectorAll(".popup__close-button").forEach((button) => {
      button.addEventListener("click", () => {
        closePopup();
      });
    });
    
    document.querySelectorAll(".popup__overlay").forEach((overlay) => {
      overlay.addEventListener("click", () => {
        closePopup();
      });
    });
    
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27 && openPopup !== null) {
        closePopup();
      }
    });
    `,
  },
  {
    title: "Viewport detect",
    description: "Adds a class 'active' for elements entering the viewport",
    html: "",
    scss: "",
    javascript: `//window height offset until active class is added
    var offset = window.innerHeight * 0.75;
    
    var osActive = function () {
      for (var i = 0; i < document.querySelectorAll(".e-in").length; i++) {
        var el = document.querySelectorAll(".e-in")[i];
    
        if (el.getBoundingClientRect().top < offset) {
          if (!el.classList.contains("active")) {
            el.classList.add("active");
          }
        } else {
          el.classList.remove("active");
        }
      }
    };
    
    if (document.querySelectorAll(".e-in").length > 0) {
      window.addEventListener("load", function () {
        osActive();
      });
    
      window.addEventListener("scroll", function () {
        osActive();
      });
    }
    `,
  },
  {
    title: "Swiper carousel",
    description: "",
    html: `    <div class="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">Slide 1</div>
      <div class="swiper-slide">Slide 2</div>
      <div class="swiper-slide">Slide 3</div>
    </div>

    <div class="swiper-button-prev">prev</div>
    <div class="swiper-button-next">next</div>
  </div>`,
    scss: "",
    javascript: `import Swiper, { Navigation } from "swiper";

    import "swiper/css";
    // import "swiper/css/navigation";
    
    Swiper.use([Navigation]);
    
    document.querySelectorAll(".swiper").forEach((el) => {
      new Swiper(el, {
        loop: true,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    });
    `,
  },
  {
    title: "Tooltip",
    description: "",
    html: `    <div style="text-align: center">
    <button class="tooltip-button" aria-describedby="tooltip-1" aria-controls="tooltip-1">Tooltip button</button>
  </div>

  <div class="tooltip" id="tooltip-1">
    <div class="tooltip__content">I'm a tooltip<a href="#">hello test link</a> <a href="#">another link</a></div>
  </div>`,
    scss: `.tooltip {
      visibility: hidden;
      transition: visibility 0s 0.3s;
    
      &__content {
        opacity: 0;
        background: red;
        transform: translateY(-10px);
        transition: opacity 0.3s 0s, transform 0.3s 0s;
      }
    }
    
    .tooltip[data-show] {
      visibility: visible;
      transition: visibility 0s 0s;
    
      .tooltip__content {
        opacity: 1;
        transform: translateY(0);
      }
    }
    `,
    javascript: `import { createPopper } from "@popperjs/core";

    const showEvents = ["mouseenter", "focus"];
    const hideEvents = ["mouseleave"];
    
    document.querySelectorAll(".tooltip-button").forEach((button) => {
      const tooltip = document.getElementById(button.getAttribute("aria-controls"));
      let toolTipItemFocused = false;
      let popperInstance = null;
    
      function show() {
        tooltip.setAttribute("data-show", "");
        popperInstance.update();
      }
    
      function hide() {
        tooltip.removeAttribute("data-show");
      }
    
      showEvents.forEach((event) => {
        button.addEventListener(event, show);
      });
    
      hideEvents.forEach((event) => {
        button.addEventListener(event, () => {
          window.requestAnimationFrame(() => {
            if (!tooltip.classList.contains("tooltip--hovered") || toolTipItemFocused) {
              hide();
            }
          });
        });
      });
    
      button.addEventListener("blur", () => {
        window.requestAnimationFrame(() => {
          if (!toolTipItemFocused) {
            hide();
          }
        });
      });
    
      // Pass the button, the tooltip, and some options, and Popper will do the
      // magic positioning for you:
      popperInstance = createPopper(button, tooltip, {
        placement: "top",
      });
    
      // check if tooltip is hovered
    
      tooltip.addEventListener("mouseenter", () => {
        tooltip.classList.add("tooltip--hovered");
      });
    
      tooltip.addEventListener("mouseleave", () => {
        tooltip.classList.remove("tooltip--hovered");
        hide();
      });
    
      // tooltip focus out
    
      let tooltipTabbableItems = tooltip.querySelectorAll(["input", "select", "a[href]", "textarea", "button", "[tabindex]"]);
    
      tooltipTabbableItems.forEach((item) => {
        item.addEventListener("focus", () => {
          item === document.activeElement ? (toolTipItemFocused = true) : (toolTipItemFocused = false);
        });
    
        item.addEventListener("blur", () => {
          tooltipTabbableItems.forEach((item) => {
            item === document.activeElement ? (toolTipItemFocused = true) : (toolTipItemFocused = false);
          });
    
          window.requestAnimationFrame(() => {
            if (!toolTipItemFocused) {
              hide();
            }
          });
        });
      });
    });
    `,
  },
  {
    title: "Burger button",
    description: "",
    html: `  <button class="menu-trigger first">
    <span></span>
    <span></span>
    <span></span>
  </button>


  <button class="menu-trigger second">
    <span></span>
    <span></span>
    <span></span>
  </button>`,
    scss: `
    .menu-trigger{
      width: 40px;
      height: 25px;
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      margin: 20px;
      appearance: none;
      border: 0;
      background: none;
      overflow: visible;
    
      span {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        background: #fff;   
      }
    }
    
    
    //first menu trigger
    
    .menu-trigger.first{
      span {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        background: #fff;
        transition: 0.3s;
    
        &:nth-of-type(1) {
          transition: all 0.3s 0.3s, transform 0.3s 0s;
        }
    
        &:nth-of-type(2) {
          top: 50%;
          margin-top: -1px;
          left: 0;
          transition: all 0.3s 0.3s;
        }
    
        &:nth-of-type(3) {
          bottom: 0;
          top: auto;
          left: 0;
          transition: all 0.3s 0.3s, transform 0.3s 0s;      
        }    
      }
      &.active {
        span:nth-of-type(1) {
          top: 50%;
          margin-top: -1px;
          transform: rotate(45deg);
          transition: all 0.3s, transform 0.3s 0.3s;
        }
    
        span:nth-of-type(2) {
          opacity: 0;
          transition: all 0.3s, opacity 0.3s 0s;      
        }
    
        span:nth-of-type(3) {
          bottom: 50%;
          margin-bottom: -1px;
          transform: rotate(-45deg);
          transition: all 0.3s, transform 0.3s 0.3s;      
        }    
      }  
    }
    
    
    //second menu trigger
    
    .menu-trigger.second{
      transition: all 0.5s 0s;
      span {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        background: #fff;
        transition: 0.3s;
        &:nth-of-type(1) {
          transition: all 0.3s 0.3s, transform 0.3s 0s;
        }
    
        &:nth-of-type(2) {
          top: 50%;
          margin-top: -1px;
          left: 0;
          transition: all 0.3s 0.3s;
        }
    
        &:nth-of-type(3) {
          bottom: 0;
          top: auto;
          left: 0;
          transition: all 0.3s 0.3s, transform 0.3s 0s;      
        }    
      }
      &.active {
        transform: rotate(135deg);
        transition: all 0.5s 0.3s;
    
        span:nth-of-type(1) {
          top: 50%;
          margin-top: -1px;
          transform: rotate(90deg);
          transition: all 0.3s, transform 0.3s 0.3s;
        }
    
        span:nth-of-type(2) {
          opacity: 0;
          transition: all 0.3s, opacity 0.3s 0s;      
        }
    
        span:nth-of-type(3) {
          bottom: 50%;
          margin-bottom: -1px;
          transform: rotate(0deg);
          transition: all 0.3s, transform 0.3s 0.3s;      
        }    
      }  
    }
    `,
    javascript: `document.querySelectorAll('.menu-trigger').forEach( (burger) => {
      burger.addEventListener('click', () => {
        burger.classList.toggle('active')
      });
    });`,
  },
]

export default data
