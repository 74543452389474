import * as React from "react"

import CodeContainer from "../components/code-container"

import Layout from "../components/layout"

import Intro from "../components/intro"

import data from "../components/data"

const IndexPage = () => (
  <Layout>
    <Intro />

    {data.map((item, index) => (
      <CodeContainer {...item} key={index} />
    ))}
  </Layout>
)

export default IndexPage
