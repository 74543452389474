import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import data from "./data"

const Heading = styled.h1`
  font-size: ${rem(50)};
`

const El = styled.div`
  padding-bottom: ${rem(20)};
  padding-top: ${rem(200)};
`

const Menu = styled.p`
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding: ${rem(20)} 0;
  border-bottom: 1px solid;
  margin-bottom: -1px;

  a {
    display: inline-block;
    margin-left: ${rem(10)};
    text-decoration: none;
    color: blue;
  }
`

const Intro = () => (
  <React.Fragment>
    <El>
      <Heading>Daumix reusaball components</Heading>
    </El>

    <Menu>
      Components:{" "}
      {data.map((item, key) => (
        <a key={key} href={`#${item.title.toLowerCase().split(" ").join("-")}`}>
          {item.title}
        </a>
      ))}
    </Menu>
  </React.Fragment>
)

export default Intro
